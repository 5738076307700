import { useState, useEffect } from 'react';
import sepiaLogo from './cantsleeplogo_sepia.svg';
import whiteLogo from './cantsleeplogo_white.svg';
import darkLogo from './cantsleeplogo_dark.svg';

const THEME_KEY = '_theme';

const themes = {
    sepia: {
        value: 'sepia',
        logo: sepiaLogo,
    },
    midnight: {
        value: 'midnight',
        logo: darkLogo,
    },
    dark: {
        value: 'dark',
        logo: darkLogo,
    },
    white: {
        value: 'white',
        logo: whiteLogo,
    }
}

const getThemeFromLocalStorage = () => {
    try {
        const theme = localStorage.getItem(THEME_KEY);
        return !!theme && [themes.sepia.value, themes.midnight.value, themes.dark.value, themes.white.value].indexOf(theme) >= 0 ? theme : themes.sepia.value;
    } catch (e) {
        return themes.sepia.value;
    }
};

export function useTheme() {
    const [theme, setTheme] = useState(() => getThemeFromLocalStorage());
    const [isThemeSelectionActive, setIsThemeSelectionActive] = useState(false);

    const [logo, setLogo] = useState();

    const flipThemeSelection = () => setIsThemeSelectionActive(prev => !prev);

    const onThemeClick = (theme) => {
        setTheme(theme);
        flipThemeSelection();
    };

    useEffect(() => {
        document.body.className = theme;
        localStorage.setItem(THEME_KEY, theme);
        setLogo(themes[theme].logo);
    }, [theme]);

    return {
        theme,
        logo,
        setTheme,
        isThemeSelectionActive,
        flipThemeSelection,
        onThemeClick,
        getThemes: () => themes,
    }
}