import path from 'path';
type Genre = 'horror' | 'fiction' | 'war';

export interface Fixture {
    id: string;
    author: string;
    date: string;
    genre: Genre[];
    title: string;
    slug: string;
    getContent: any;
}
const getContent =  (filePath: string) => {
    return fetch(filePath).then((response) => response.text()).then((text) => text);
}

export const fixtures: Fixture[] = [
    {
        id: "1",
        author: "Edgar Allan Poe",
        date: "1842",
        genre: ["horror"],
        title: "The Pit and the Pendulum",
        slug: "the-pit-and-the-pendulum",
        getContent: () => getContent(require('./stories/ThePitAndThePendulum.md')),
        // https://en.wikipedia.org/wiki/The_Pit_and_the_Pendulum
    },
    {
        id: "2",
        author: "Edgar Allan Poe",
        date: "September 1839",
        genre: ["horror"],
        title: "The Fall of the House of Usher",
        slug: "the-fall-of-the-house-of-usher",
        getContent: () => getContent(require('./stories/TheFallOfTheHouseOfUsher.md')),
        // https://en.wikipedia.org/wiki/The_Fall_of_the_House_of_Usher
    },
    {
        id: "3",
        author: "Edgar Allan Poe",
        date: "January 1843",
        genre: ["horror"],
        title: "The Tell-Tale Heart",
        slug: "the-tell-tale-heart",
        getContent: () => getContent(require('./stories/TheTellTaleHeart.md')),
        // https://en.wikipedia.org/wiki/The_Masque_of_the_Red_Death
    },
    {
        id: "4",
        author: "Edgar Allan Poe",
        date: "May 1842",
        genre: ["horror"],
        title: "The Masque of the Red Death",
        slug: "the-masque-of-the-red-death",
        getContent: () => getContent(require('./stories/TheMasqueOfTheReadDeath.md')),
        // https://en.wikipedia.org/wiki/The_Tell-Tale_Heart
    },
    {
        id: "5",
        author: "Mark Twain",
        date: "November 18, 1865",
        genre: ["fiction"],
        title: "The Celebrated Jumping Frog of Calaveras County",
        slug: "the-celebrated-jumping-frog",
        getContent: () => getContent(require('./stories/TheCelebratedJumpingFrogOfCalaverasCounty.md')),
        // https://en.wikipedia.org/wiki/The_Celebrated_Jumping_Frog_of_Calaveras_County
    },
    {
        id: "6",
        author: "Charlotte Perkins Gilman",
        date: "January, 1892",
        genre: ["fiction"],
        title: "The Yellow Wallpaper",
        slug: "the-yellow-wallpaper",
        getContent: () => getContent(require('./stories/TheYellowWallpaper.md')),
        // https://en.wikipedia.org/wiki/The_Yellow_Wallpaper
    },
    {
        id: "7",
        author: "Ambrose Bierce",
        date: "July 13, 1890",
        genre: ["fiction", "war"],
        title: "An Occurrence At Owl Creek Bridge",
        slug: "occurrence-at-owl-creek-bridge",
        getContent: () => getContent(require('./stories/AnOccurrenceAtOwlCreekBridge.md')),
        // https://en.wikipedia.org/wiki/An_Occurrence_at_Owl_Creek_Bridge
    },
    {
        id: "8",
        author: "O. Henry",
        date: "December 10, 1905",
        genre: ["fiction"],
        title: "The Gift of the Magi",
        slug: "the-gift-of-the-magi",
        getContent: () => getContent(require('./stories/TheGiftOfTheMagi.md')),
        // https://en.wikipedia.org/wiki/The_Gift_of_the_Magi
    },
    {
        id: "9",
        author: "W. W. Jacobs",
        date: "September, 1902",
        genre: ["horror"],
        title: "The Monkey's Paw",
        slug: "the-monkeys-paw",
        getContent: () => getContent(require('./stories/TheMonkeysPaw.md')),
        // https://en.wikipedia.org/wiki/The_Monkey%27s_Paw
    },
    {
        id: "10",
        author: "Mark Twain",
        date: "1893",
        genre: ["fiction"],
        title: "The Million Pound Bank Note",
        slug: "the-million-pound-bank-note",
        getContent: () => getContent(require('./stories/TheMillionPoundBankNote.md')),
    }
];