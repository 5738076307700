import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './app';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { fixtures } from './fixtures';
import { Story } from './story';

function getRandomStory() {
  return fixtures[Math.floor(Math.random()*fixtures.length)];
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/story/:story">
          <Story />
        </Route>
        <Route path="/">
          <Redirect to={`/story/${getRandomStory().slug}`} />
        </Route>
        <Route>
          <div>Not found??</div>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
