import React, { useEffect, useState, useCallback } from 'react';
import { fixtures, Fixture } from './fixtures';
import { useTheme } from './useTheme';
import { useRouteMatch, useHistory } from "react-router-dom";

const ReactMarkdown = require('react-markdown');

function debounce(func, wait) {
    let timeout;
    
    return function(...args) {
        clearTimeout(timeout);
        
        timeout = setTimeout(() => {
            func(args);
        }, wait);
    };
}

export const Story = () => {
    const [content, setContent] = useState<string>('');
    const [fixture, setFixture] = useState<Fixture | null>(null);
    const [readingTime, setReadingTime] = useState<string>('');
    const [showButton, setShowButton] = useState<Boolean>(true);
    const { params } = useRouteMatch("/story/:story");
    const history = useHistory();

    const { theme, logo, onThemeClick, isThemeSelectionActive, flipThemeSelection, getThemes } = useTheme();
  
    useEffect(() => {
      async function getStoryContent() {
        const matchedFixture = fixtures.find(f => f.slug === params.story);
        if (!matchedFixture) {
            window.location.href = '/';
            return;
        }
        const retrievedContent = await matchedFixture.getContent();
        setContent(retrievedContent);
        setFixture(matchedFixture);
        setReadingTime(getEstimatedReadingTime(retrievedContent));
      }

      getStoryContent();
    }, [history, params.story]);

    const handleScroll = useCallback(
        debounce(() => {
            console.log('firing!!!');
            setShowButton(true);
        }, 3000),
        []
    );

    // useEffect(() => {
        

    //     const onScroll = () => {
    //         setShowButton(false);
    //         handleScroll();
    //     };
    
    //       window.addEventListener('scroll', onScroll);
    
    //       return () => {
    //         window.removeEventListener('scroll', onScroll);
    //       }
    // }, [showButton, setShowButton])
  
    const getEstimatedReadingTime = (words) => {
      const avgWordsPerMinute = 200;
      const numWords = words.split(' ').length;
      return `${Math.floor(numWords / avgWordsPerMinute)} minutes`;
    }

    if (!fixture) return null;
  
    return (
      <div className="content">
        <div className="pre">
          <div className="logo">
            <img className="cant-sleep-logo" src={logo} alt="Can't sleep logo" loading="lazy" onClick={flipThemeSelection} />
            {isThemeSelectionActive &&
              <div className="theme-selector">
                <div className="themes">
                  {Object.values(getThemes()).map(obj => obj.value === theme ? null :
                    <div 
                      className={obj.value}
                      onClick={() => onThemeClick(obj.value)}
                    />
                  )}
                </div>
              </div>
            }
          </div>
  
          <p className="estimate">Reading time: {readingTime}</p>
        </div>
        <h1>{fixture.title}</h1>
        <div className="author">
          <h4>By {fixture.author}, {fixture.date}</h4>
        </div>
        <article>
          <ReactMarkdown source={content} />
        </article>
        <div className="next-story">
            <span className="next-story-btn" onClick={() => window.location.href = '/'}>Next story</span>
         </div>
      </div>
    );
};